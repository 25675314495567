import merge from 'lodash/merge';
import hoverFormats from './hoverFormats';
import images from './images';
import hoverTemplates from './hoverTemplates';
import { Enums } from '@flightscope/baseball-stats';

const plotlyLayout = {
  // get rid of all the extra whitespace around plots
  margin: {
    t: 25,
    // b: 25,
    // r: 25,
    // l: 25
  },
  showlegend: true,
  hovermode: 'closest',
  hoverlabel: { bgcolor: '#FFF' },
  font: {
    family: '"Roboto", sans-serif'
  }
};

const scatterChartLayout = {
  margin: {
    t: 25,
  },
  xaxis: {
    automargin: true,
    autotick: false,
    title: '',
    titleWithoutUnit: '',
    constrain: 'domain',

    // should change depending on used unit system
    // range: [-90, 90],
    dtick: 10,

    // will change when using proper hovertemplate
    hoverformat: hoverFormats.TWO_DIGIT_FLOAT,
    tickformat: hoverFormats.ONE_DIGIT_FLOAT,

    zerolinecolor: 'rgba(68,68,68,0.5)',
  },

  yaxis: {
    automargin: true,
    autotick: false,
    title: '',
    titleWithoutUnit: '',
    constrain: 'domain',
    constraintoward: 'top',

    // needed for fixed-aspect-ratio
    scaleanchor: 'x',

    // should change depending on used unit system
    // range: [0, 140],
    dtick: 10,

    // will change when using proper hovertemplate
    hoverformat: hoverFormats.TWO_DIGIT_FLOAT,
    tickformat: hoverFormats.ONE_DIGIT_FLOAT,

    zerolinecolor: 'rgba(68,68,68,0.5)',
  },

  title: {
    text: '',
    xref: 'paper',
    xanchor: 'center',
  },
};

const baseballSprayChartLayout = merge({}, scatterChartLayout, {
  xaxis: {
    // should change depending on used unit system
    // range: [-90, 90],
    // zerolinecolor: 'rgba(68,68,68,0.5)',
    visible: false,
  },
  yaxis: {
    // range: [0, 140],
    // zerolinecolor: 'rgba(68,68,68,0.5)',
    visible: false,
  },
  images: [images.baseballCourtBG],
  hovertemplate: '%{text}'
});

const softballSprayChartLayout = merge({}, scatterChartLayout, {
  xaxis: {
    // should change depending on used unit system
    range: [-60, 60],
    // constrain: 'domain',
    // constraintoward: 'center',
    zerolinecolor: 'rgba(68,68,68,0.5)',
  },
  yaxis: {
    // should change depending on used unit system
    range: [0, 80],
    // constrain: 'domain',
    // constraintoward: 'middle',
    // rangemode: 'nonnegative',
    zerolinecolor: 'rgba(68,68,68,0.5)',
  },
  images: [images.softballCourtBG],
});

const sprayChartLayout = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key
        ? baseballSprayChartLayout
        : softballSprayChartLayout;

const performanceTrackingLayout = {
  showlegend: false,
  xaxis: {
    automargin: true,
    autotick: false,
    showgrid: false,
    // TODO: disabled for autoaggregating of PC
    dtick: 5,
    zerolinecolor: 'rgba(68,68,68,0.5)',
  },
  yaxis: {
    automargin: true,
    autotick: true,
    hoverformat: hoverFormats.TWO_DIGIT_FLOAT,
    tickformat: hoverFormats.ONE_DIGIT_FLOAT,
    zerolinecolor: 'rgba(68,68,68,0.5)',
  },

  title: {
    text: '',
    xref: 'paper',
    xanchor: 'center',
  },
};

const performanceTrackingLayoutFn = (x, enumObj) => {
  return {
    title: '',
    margin: {
      t: 50,
    },
    xaxis: {
      automargin: true,
      zerolinecolor: 'rgba(68,68,68,0.5)',
      // type: 'linear',
      // needs to be set like to work on linear axis,
      range: [x.length > 100 ? x.length - 100 : 1, x.length],
      rangeslider: {
        visible: true,
        range: [1, x.length],
      },
    },

    yaxis: {
      automargin: true,
      hoverformat: hoverFormats.TWO_DIGIT_FLOAT,
      tickformat: hoverFormats.ONE_DIGIT_FLOAT,
      zerolinecolor: 'rgba(68,68,68,0.5)',
      fixedrange: true,
      title: {
        // TODO: should be set depending on parameter
        text: enumObj.name,
      },
    },

    title: {
      text: '',
      xref: 'paper',
      xanchor: 'center',
    },
  };
};

const barchartLayout = {
  barmode: 'group',
  xaxis: {
    range: [0, 100],
    automargin: true,
    fixedrange: true,
  },
  yaxis: {
    automargin: true,
    fixedrange: true,
  },
  legend: {
    traceorder: 'reversed',
  }
};

const heatmapChartLayout = {
  automargin: true,
  margin: {
    t: 25,
  },
  showlegend: false,
  xaxis: {
    constrain: 'domain',
    range: [-2, 2],
  },

  yaxis: {
    automargin: true,
    constrain: 'domain',
    constraintoward: 'top',
    // needed for fixed-aspect-ratio
    scaleanchor: 'x',
  },

  title: {
    text: '',
    xref: 'paper',
    xanchor: 'center',
  },
};

const pitchMovementChartLayout = merge({}, {
  xaxis: {
    zerolinewidth: 1.5,
    zerolinecolor: 'rgba(68,68,68,0.5)',
  },
  yaxis: {
    zerolinewidth: 1.5,
    zerolinecolor: 'rgba(68,68,68,0.5)',
  },
})

const scatterpolarChartLayout = {
  legend: {
    x: 1.5,
    xanchor: "right",
    y: 1
  },
  margin: {
    r: 20,
    t: 95,
    b: 30,
    l: 330,
  },
  polar: {
    sector: [-90, 90],
    angularaxis: {
      dtick: 45
    },
    radialaxis: {
      range: [0, 65],
      dtick: 10,
      tickfont: {
        size: 11
      },
      tickangle: -90,
    }
  },
  title: {
    text: 'Scatterpolar Chart',
    font: {
      size: 16
    }
  },
};

export default {
  base: plotlyLayout,
  barchart: barchartLayout,
  scatterChart: scatterChartLayout,
  sprayChart: sprayChartLayout,
  performanceTrackingChart: performanceTrackingLayout,
  heatmapChart: heatmapChartLayout,
  pitchMovementChart: pitchMovementChartLayout,
  scatterpolarChart: scatterpolarChartLayout,
  hoverTemplates,
  performanceTrackingLayoutFn,
};
